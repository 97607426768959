<template>
  <div id="app">
		<h1>游戏客户端测试</h1>
		<button type="button" @click="getTGdata">获取用户信息</button>
		<p>
			<textarea v-if="tgdata" style="width: 100%;height:200px" v-model="tgdata"></textarea>
		</p>
		<button type="button" @click="getWallet">获取用户钱包信息</button>
		<p>
			<textarea v-if="walletInfo" style="width: 100%;height:200px" v-model="walletInfo"></textarea>
		</p>
		<button type="button" @click="orderLink">发起订单</button>
		<p></p>
		<button type="button" @click="shareLink">分享链接</button>
		
		<p>
			<textarea v-if="error" style="width: 100%;height:200px" v-model="error"></textarea>
		</p>
  </div>
</template>

<script>
import { retrieveLaunchParams,initUtils } from '@telegram-apps/sdk';
import TonConnect from '@tonconnect/ui';
export default {
  name: 'App',
  data() {
      return {
        tgdata: null,
		walletInfo:null,
        error:null
      }
    },
	mounted() {
	// 	import { retrieveLaunchParams,initUtils } from '@telegram-apps/sdk';
			
	// 	//用户登陆	
	// 	//获取用户初始化信息
	// 	const { initDataRaw } = await retrieveLaunchParams();
	
	// 	//将这个	this.tgdata 作为参数传给服务器		
	// 	this.tgdata = initDataRaw;
		
	// 	//分享游戏
	// 	const utils = await initUtils();
	// 	//第一个参数是分享链接，第二个参数是分享文案				
	// 	utils.shareURL("t.me/liqulxyz_bot?game=test_game","分享测试小游戏");
		
	},
	methods:{
		async getTGdata(){
			try{
				const { initDataRaw } = await retrieveLaunchParams();
						
				this.tgdata = initDataRaw;
			
			}catch(error){
				console.log(error)		
			}
			
		},
		getWallet(){
			const tonConnect = new TonConnect();
			tonConnect.connectWallet().then((walletInfo) => {
				this.error = JSON.stringify({address: walletInfo.address});

				const paymentRequest = {
					to: 'UQDYVqAGzURxCtzTvOZB73sXORKXuGUeyuiYs2dzWvieyMgY',    // 收款方的钱包地址
					value: '0.1',         // 支付金额，以 TON 计
					data: '支付相关的备注信息', // 可选的备注信息
				};
					
				// 请求用户签名并发起支付
				tonConnect.sendTransaction(paymentRequest).then((response) => {
					console.log('支付成功，交易详情: ', response);
				}).catch((err) => {
					console.error('支付失败: ', err);
				});
				tonConnect.on('statusChange', (status) => {
					if (status === 'transactionSent') {
						console.log('交易已发送，等待确认...');
					} else if (status === 'transactionConfirmed') {
						console.log('交易确认成功！');
					} else {
						console.log('交易状态: ', status);
					}
				});
			}).catch((err) => {
					this.error = JSON.stringify(err);
			});
		},

		async orderLink(){
			try {
				const utils = await initUtils();
				utils.openLink("ton://transfer/UQDYVqAGzURxCtzTvOZB73sXORKXuGUeyuiYs2dzWvieyMgY?amount=100000000&text=Order:order_66dad9c05eea29.06979686");
			} catch (error) {
				this.error = JSON.stringify(error);
			}
		},
		
		async shareLink(){
			try {
				const utils = await initUtils();
				
				utils.shareURL("t.me/liqulxyz_bot?game=test_game","分享测试小游戏");
			} catch (error) {
				this.error = JSON.stringify(error);
			}
		}
	}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>